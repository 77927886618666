@import url("http://fonts.cdnfonts.com/css/circular-std-book");

body {
  font-family: "Circular Std Book", sans-serif;
}

h1 {
  font-family: "Circular Std Black", sans-serif !important;
  font-weight: 900;
  font-size: 3.75rem !important;
  line-height: 1 !important;
}
h2 {
  font-family: "Circular Std Black", sans-serif !important;
  font-weight: 900;
  font-size: 2.25rem !important;
line-height: 2.5rem !important;
  
}
h4 {
  font-size: 1.25rem;
line-height: 1.75rem;
  font-weight: 700;
}

h6 {
  font-size: 1.125rem;
line-height: 1.75rem;
font-weight: 600;
color: #1E3A8A;
 
}

p {
  color: #9CA3AF !important;
  line-height: 1.75rem !important;
 
}

.yellow-box {
  background: #feab30;
  box-shadow: 0px 12px 25px rgba(252, 150, 0, 0.3);
  border-radius: 10px;

}
.blue-box {
  background: #2e81ff;
  box-shadow: 0px 12px 25px rgba(0, 101, 255, 0.3);
  border-radius: 10px;
}
.green-box {
  background: #2dbd20;
  box-shadow: 0px 12px 25px rgba(19, 176, 4, 0.3);
  border-radius: 10px;
}
.pink-box {
  background: #bc30fe;
  box-shadow: 0px 10px 25px rgba(141, 0, 207, 0.25);
  border-radius: 10px;
}
.blue-box-2 {
  background: #2e81ff;
  box-shadow: 0px 10px 25px rgba(46, 129, 255, 0.25);
  border-radius: 10px;
 
}

.green-box-2 {
  background: #45b36b;
  box-shadow: 0px 10px 25px rgba(69, 179, 107, 0.25);
  border-radius: 10px;
 
}

.orange-box {
  background: #fe7a30;
  box-shadow: 0px 10px 25px rgba(254, 122, 48, 0.25);
  border-radius: 10px;

}

.red-box {
  background: #dd5353;
  box-shadow: 0px 10px 25px rgba(221, 83, 83, 0.25);
  border-radius: 10px;

}

/* .card {
  @apply rounded-lg p-8 w-80 h-96 text-center bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer;
} */

/* .card2 {
  @apply rounded-lg p-8  text-left bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer;
} */
/* .card3 {
  @apply flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer;
} */

/* .card4 {
  @apply rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer;
} */
/* .card5 {
  @apply rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer;
} */
/* .card6 {
  @apply flex items-center justify-between w-full p-4 rounded-md transition duration-200 ease-in-out hover:translate-x-2 transform cursor-pointer;
} */

/* .card7 {
  @apply bg-white flex flex-col items-center justify-center w-64 px-4 py-12 rounded-md  shadow-xl transition duration-200 ease-in-out hover:translate-y-2  transform cursor-pointer;
} */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
