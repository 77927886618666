.right-sidebar {
  &.right-sidebar-1 {
    @apply text-sm w-64 transform transition duration-300 ease-in-out shadow fixed top-0 bottom-0 h-full overflow-hidden;
    @apply z-10;
    @apply right-0;
    &.open {
      @apply translate-x-0;
    }
    &:not(.open) {
      @apply translate-x-64;
    }
    > div {
      @apply absolute top-0 left-0 bottom-0 h-full overflow-auto;
      > div {
        @apply h-full w-64;
      }
    }
  }
}

[data-right-sidebar="light"] {
  .right-sidebar {
    @apply bg-white text-grey-900;
  }
}

[data-right-sidebar="dark"] {
  .right-sidebar {
    @apply bg-grey-800 text-white;
  }
}
